import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
    init(){
        console.log('Nylon | Route: Home');
        gsap.set('.left-element', {autoAlpha: 0, left: '-=100px'});
        gsap.set('.right-element', {autoAlpha: 0, right: '-=100px'});
        gsap.set('.top-element', {autoAlpha: 0, top: '-=100px'});
        gsap.set('.bottom-element', {autoAlpha: 0, bottom: '-=100px'});
        gsap.set('.center-element', {autoAlpha: 0, scale: 0});

        var tl = gsap.timeline();
        tl.to('.left-element', {autoAlpha: 1, left: '+=100px'});
        tl.to('.right-element', {autoAlpha: 1, right: '+=100px'});
        tl.to('.top-element', {autoAlpha: 1, top: '+=100px'});
        tl.to('.bottom-element', {autoAlpha: 1, bottom: '+=100px'});
        tl.to('.center-element', {autoAlpha: 1, scale: 1});

        gsap.to('.center-element img', {rotation : "1080", transformOrigin : 'center', duration: 60, ease: 'linear', repeat: -1, timeScale: 1});



        const scrollToButton = document.querySelector('.center-element');

        scrollToButton.addEventListener('click', (e) => {
        e.preventDefault();
        gsap.to(window, {
            scrollTo: {
            y: '#sizzle-block', // Replace with the ID or position you want to scroll to
            offsetY: 50, // Optional offset from the top of the target
            },
            duration: 1, // Duration of the scroll animation
            ease: 'power2.inOut', // Easing function
        });
        return false;
        });

        // Case Studies

        let caseImages = $('#case-studies img');

        gsap.set(caseImages, {autoAlpha: 0, y: 10});

        gsap.to(caseImages, {
			scrollTrigger : {
				trigger: "#case-studies",
				start: "top center",
				end : "bottom",
            },
            autoAlpha : .7,
            y:0,
            duration : 1,
            stagger: .2
        });

        let listings = $('#verticals .listing__item');


        gsap.set(listings, {autoAlpha: 0, x: 10});

        gsap.to(listings, {
			scrollTrigger : {
				trigger: "#verticals",
				start: "top center",
				end : "bottom",
            },
            autoAlpha : 1,
            x:0,
            duration : 1,
            stagger: .2
        });
    },
    finalize(){}
}