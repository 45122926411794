
const WebFont = require('webfontloader');
import Carousels from '../modules/carousels';
import Utilities from '../modules/utilities';
import Navigations from '../modules/navigations';
import Sticky from '../modules/sticky';
import Sizzle from '../modules/sizzle';
import Animations from '../modules/animations';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {

    init(){
        Utilities.init();
        Navigations.init();
        Carousels.init();
        Sizzle.init();
        AOS.init();
        Animations.init();
        Sticky.init();
        WebFont.load({
            typekit: {
                id: 'svh0jca'
            }
        });
    },
    finalize(){}
}