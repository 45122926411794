import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
	init() {
   var windowW = $(window).width();



		gsap.to(".menu-open", {
			scrollTrigger : {
				trigger: ".global-main",
				start: "+=250",
				endTrigger : '.global-footer',
				end : 'top',
				toggleActions : 'play none none reverse',
			},
			backgroundColor : '#00E0BF'
		});
	 
	},
};
