
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, DrawSVGPlugin);

export default {
	init() {
    $(document).ready(function() {
      // Function to handle smooth scrolling
      function scrollToTarget(target) {
        $('html, body').animate({
          scrollTop: target.offset().top // Adjust offset as needed
        }, 1000); // Animation duration in milliseconds
      }
  
      // Add click event listeners to anchor links
      $('a[href^="#"]').on('click', function(e) {
        e.preventDefault();
        const target = $($(this).attr('href'));
        if (target.length) {
          scrollToTarget(target);
        }
      });

      $('.chat-icon a').on('click', function(e){
        e.preventDefault();
      })
    });

    // Logo Animation
    var shapes = ".husl-logo-svg path";
    var huslLogo = ".husl-logo";
    var huslLogoSvg = ".husl-logo-svg";


    gsap.set(huslLogo, {autoAlpha:1});
    if(huslLogoSvg){
      var letters404 = $('.title-404 span');
      var text404 = $('.text-404');
      gsap.set(letters404, {scaleY: 0, y: 30});
      gsap.set(text404, {autoAlpha:0, scaleY: 0, transformOrigin : 'bottom'});
      var tl = gsap.timeline({repeat:0});
      tl.to(letters404, {scaleY: 1, y: 0, duration:1, stagger : .5})
        .to(text404, {autoAlpha:1, scaleY: 1})
    }

    var homeService = $('#home-services');
    if(homeService){
      var homeServiceLi = $('.sub-services li');
      gsap.set(homeServiceLi, {autoAlpha: 0, x: 20});
      gsap.to(homeServiceLi, {
        scrollTrigger : {
          trigger: "#home-services",
          start: "top center",
          end : "bottom",
        },
        autoAlpha : 1,
        x:0,
        duration : .3,
        stagger: .1
      });
    }

    // Cards
    let cardsSection = $('.husl-block__container--cards');

    if(cardsSection) {
      let cards = $('.husl-block__container--cards .card');

      gsap.set(cards, {autoAlpha: 0, y: -20});

      gsap.to(cards, {
        scrollTrigger : {
          trigger: ".husl-block__container--cards",
          start: "top center",
          end : "bottom",
        },
        autoAlpha : 1,
        y:0,
        duration : 1,
        stagger: .2
      });

    }
	},
};
