import jqueryMatchHeight from 'jquery-match-height';
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';
import imagesLoaded from 'imagesloaded';

export default {
	init() {
		$('.attachment-btn').on('click', function(e){
			e.preventDefault();
			$(this).fadeOut(function(){
				$('.ginput_container_fileupload input[type=file]').trigger('click');
				$('.ginput_container_fileupload').delay(500).fadeIn();
			});
		});

		$('.gfield input').on("click",function(){
			if ($(this).is( ":focus" )) {
					$(this).parent('div').parent('li').find('label').addClass('active');
			}else{
									$(this).focus();
					// fire this step
			}
		});

		$('.gfield input').each(function(){
			if($(this).val()){
				$(this).parent('div').parent('li').find('label').addClass('active');
			}
		})
		

		jQueryBridget('masonry', Masonry, $);
		jQueryBridget( 'imagesLoaded', imagesLoaded, $ );

		$('.nav-tabs a').on('click', function (e) {
			e.preventDefault();
			var target = $(this).data('target');
			$('.nav-tabs a').removeClass('active');
			$(this).addClass('active');

			$('.tab-content').removeClass('active');
			$(target).addClass('active');
		});

		$('.accordion-head a').on('click', function (e) {
			e.preventDefault();
			$('.accordion-head').removeClass('active');
			$(this).parent('.accordion-head').addClass('active');
			var target = $(this).data('target');
			$('.accordion-content.open').slideUp();
			$(target).slideDown().addClass('open');
		});

		$('.difference-trigger').on('click', function (e) {
			e.preventDefault();
			var target = $(this).attr('href');
			if ($(this).hasClass('active')) {
				return;
			}
			$('.difference-trigger').removeClass('active');
			$(this).addClass('active');
			$('.difference-item').fadeOut(1);
			$(target).delay('1000').fadeIn('200');
		});
		$('.difference-trigger').on('mouseenter', function (e) {
			e.preventDefault();
			var target = $(this).attr('href');
			if ($(this).hasClass('active')) {
				return;
			}
			$('.difference-trigger').removeClass('active');
			$(this).addClass('active');
			$('.difference-item').fadeOut(1);
			$(target).delay('200').fadeIn('200');
		});
		$('.vertical').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false,
		});

		const $container = $('.gallery-masonry');

		$container.imagesLoaded(() => {
			$container.masonry({
				itemSelector: '.grid-item',
				columnWidth: '.grid-item',
				gutter : 40
			});
		});
	},
};
