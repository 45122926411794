import Router from './router';
import utilities from './modules/utilities';
import common from './routes/common';
import home from './routes/home';
import blog from './routes/blog';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** Blog page */
  blog,
});

/** Load Events */
$(() => routes.loadEvents());
