import { slick } from "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default {
  init() {
    var windowW = $(window).width();
    $(".stage-carousel").slick({
      infinite: true,
      speed: 4000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: "linear",
      centerMode: true,
      slidesToShow: 1,
      variableWidth: true,
    });

    $(".carousel-images").slick({
      infinite: true,
      slidesToShow: 3,
      arrows: true,
      responsive: [
        {
          breakpoint: 1460,
          settings: {
            slidesToShow: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            arrows: true,
          },
        },
      ],
    });

    $(".carousel-container").slick({
      infinite: true,
      slidesToShow: 4,
      autoplay: true,
      autoplaySpeed: 4500,
      responsive: [
        {
          breakpoint: 1460,
          settings: {
            slidesToShow: 2,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            variableWidth: true,
            centerMode: false,
            arrows: true,
          },
        },
      ],
      appendArrows: ".approach-carousel .approach-slick-buttons",
    });

    // Case Studies Carousel
    $(".clients-logos").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: true,
      arrows: false,
      centerPadding: "20px",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            centerMode: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2500,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            centerMode: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2500,
          },
        },
      ],
    });

    var slider = $(".results-slider");
    var scrollCount = null;
    var scroll = null;

    slider.slick({
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3500,
    });

    // slider.on('wheel', function (e) {
    // 	e.preventDefault();

    // 	clearTimeout(scroll);
    // 	scroll = setTimeout(function () {
    // 		scrollCount = 0;
    // 	}, 200);
    // 	if (scrollCount) return 0;
    // 	scrollCount = 1;

    // 	if (e.originalEvent.deltaY < 0) {
    // 		$(this).slick('slickNext');
    // 	} else {
    // 		$(this).slick('slickPrev');
    // 	}
    // });

    let blocked = false;
    let blockTimeout = null;
    let prevDeltaY = 0;

    // $('.results-slider').on('mousewheel DOMMouseScroll wheel', function (e) {
    // 	let deltaX = e.originalEvent.deltaX;
    // 	let deltaY = e.originalEvent.deltaY;

    // 	if (typeof deltaY != 'undefined') {
    // 		clearTimeout(blockTimeout);
    // 		blockTimeout = setTimeout(function () {
    // 			blocked = false;
    // 		}, 50);

    // 		if (
    // 			deltaY < 1 &&
    // 			deltaY > -1 &&
    // 			((deltaX > 10 && deltaX > prevDeltaX) ||
    // 				(deltaX < -10 && deltaX < prevDeltaX) ||
    // 				!blocked)
    // 		) {
    // 			e.preventDefault();
    // 			e.stopPropagation();

    // 			blocked = true;
    // 			prevDeltaX = deltaX;

    // 			if (deltaX > 0) {
    // 				$(this).slick('slickNext');
    // 			} else {
    // 				$(this).slick('slickPrev');
    // 			}
    // 		}
    // 	}
    // });

    $(".carousel--philosophy").slick({
      infinite: true,
      centerMode: false,
      slidesToShow: 1,
      variableWidth: true,
      arrows: true,
      autoplay: true,
      draggable: true,
      autoplaySpeed: 3500,
      responsive: [
        {
          breakpoint: 1460,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $(".carousel--philosophy .carousel-item__button").on("click", () => {
      $(".carousel--philosophy").slick("slickNext");
    });

    if (windowW > 1024) {
      $(".carousel--perks").slick({
        infinite: true,
        centerMode: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1460,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              arrows: false,
            },
          },
        ],
      });
    } else {
      $(".carousel--perks").slick({
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToScroll: 1,
            },
          },
        ],
      });
    }

    $(window).on("resize", function () {
      if (windowW > 1024) {
        $(".carousel--perks").slick({
          infinite: true,
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 1460,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: false,
              },
            },
          ],
        });
      } else {
        $(".carousel--perks").slick({
          infinite: true,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          arrows: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToScroll: 1,
              },
            },
          ],
        });
      }
    });

    // Case Studies Carousel
    // $('.case-carousel').slick({
    // 	slidesToShow: 1,
    // 	slidesToScroll: 1,
    // 	arrows: true,
    // 	fade: true,
    // 	// asNavFor: '.case-selector',
    // 	// appendArrows: '.case-selector-buttons',
    // });

    $(".case-selector").slick({
      slidesToShow: 4,
      slidesToScroll: 4,
      // asNavFor: '.case-carousel',
      infinite: true,
      centerMode: true,
      focusOnSelect: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 8000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1,
            centerMode: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });

    $(".carousel--vertical").slick({
      vertical: true,
      slidesToShow: 1,
      verticalSwiping: true,
      dots: true,
      arrows: false,
      // appendDots: $('carousel-vertical-dots'),
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3200,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 10,
            infinite: false,
            autoplay: false,
          },
        },
      ],
    });

    const servicesSlider = $(".carousel-services");

    function onSliderAfterChange(event, slick, currentSlide) {
      $(event.target).data("current-slide", currentSlide);
    }

    function onSliderWheel(e) {
      var deltaY = e.originalEvent.deltaY,
        $currentSlider = $(e.currentTarget),
        currentSlickIndex = $currentSlider.data("current-slide") || 0;

      if (
        // check when you scroll up
        (deltaY < 0 && currentSlickIndex == 0) ||
        // check when you scroll down
        (deltaY > 0 &&
          currentSlickIndex == $currentSlider.data("slider-length") - 1)
      ) {
        return;
      }

      e.preventDefault();

      if (e.originalEvent.deltaY < 0) {
        $currentSlider.slick("slickPrev");
      } else {
        $currentSlider.slick("slickNext");
      }
    }

    if (windowW > 1024) {
      servicesSlider
        .each(function (index, element) {
          var $element = $(element);
          // set the length of children in each loop
          // but the better way for performance is to set this data attribute on the div.slider in the markup
          $element.data("slider-length", $element.children().length);
        })
        .slick({
          vertical: true,
          slidesToShow: 1,
          verticalSwiping: true,
          dots: true,
          arrows: false,
          // appendDots: $('carousel-vertical-dots'),
          infinite: false,
          autoplay: true,
          autoplaySpeed: 5200,
          responsive: [
            {
              breakpoint: 990,
              settings: {
                slidesToShow: 4,
              },
            },
          ],
        })
        .on("afterChange", onSliderAfterChange)
        .on("wheel", onSliderWheel);
    }

    $(".carousel--service-details").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      // centerMode: true,
      focusOnSelect: true,
      variableWidth: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1,
            centerMode: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
          },
        },
      ],
    });
  },
};
