import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
	init() {
    let videoElem = document.querySelector(".video-reel");
    if(videoElem){
      let src = videoElem.currentSrc || videoElem.src;
      gsap.set(videoElem, {autoAlpha:0, scale: .5});
  
      // Initialize the video as muted and autoplayed
      videoElem.muted = true;
      videoElem.autoplay = true;
  
      gsap.to(".video-reel", {
        scrollTrigger : {
          trigger: "#sizzle-block",
          start: "top center",
          end : "bottom",
          onEnter: () => videoElem.play(),
          onEnterBack: () => videoElem.play(),
          onLeave: () => videoElem.pause(),
          onLeaveBack: () => videoElem.pause()
        },
        autoAlpha : 1,
        scale : 1,
        duration : 2
      });
  
      videoElem.addEventListener('click', () => {
        videoElem.muted = !videoElem.muted;
        videoElem.controls = !videoElem.controls;
      });

      const containerElem = document.querySelector('#video-sizzle'); // Change this to match your container element

      containerElem.addEventListener('mouseover', () => {
        videoElem.controls = true;
      });

      containerElem.addEventListener('mouseleave', () => {
        videoElem.controls = false;
      });
    }
	},
};
