export default {
	init() {
		$('.menu-button a, .mobile-menu-opener a').on('click', function (e) {
			e.preventDefault();
			if ($(this).hasClass('menu-open')) {
				$('#navigation').fadeIn();
			} else if ($(this).hasClass('menu-close')) {
				$('#navigation').fadeOut();
			}
			$(this).toggleClass('menu-open');
			$(this).toggleClass('menu-close');
		});

		$('.js--position-popup-btn').on('click', function (e) {
			e.preventDefault();
			const idToTarget = $(this).data('open');
			// $(this).toggleClass('popup--open');
			$('body').toggleClass('body--popup-open');
			$(`#popup--${idToTarget}`).fadeIn();
			// $(`#popup--${idToTarget}`).toggleClass('popup--open');

			// console.log(idToTarget);
		});

		$('.js--popup-close').on('click', function (e) {
			e.preventDefault();
			const idToTarget = $(this).data('close');
			if ($('body').hasClass('body--popup-open')) {
				$('body').removeClass('body--popup-open');
			}
			$(`#popup--${idToTarget}`).fadeOut();
			// $(`#popup--${idToTarget}`).removeClass('popup--open');
		});

		$('.menu-item-has-children').hover(function(){
			$(this).find('> .sub-menu').stop(true, true).delay(600).slideDown(700);
		}, function(){
			$(this).find('> .sub-menu').stop(true, true).slideUp(500);
		})
	},
};
