import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

export default {
  init(){
    $('.blog-menu a').on('click', function(e){
      e.preventDefault();
      $('.blog-menu a').removeClass('active');
      $(this).addClass('active');
      var target = $(this).attr('href');
      var data = {
        'target' : target,
        'action' : 'filter_blogposts'
      }

      $.ajax({
        url : '/wp-admin/admin-ajax.php',
        data : data,
        type : 'POST',
        beforeSend : function(){
          $('.ajax-container').html(' ');
          gsap.to(window, {duration: 1, scrollTo: 500});
        },
        success : function(response){
          $('.ajax-container').html(response);
        }
      });
    });

    $('.blog-menu-select').on('change', function(e){
      e.preventDefault();
      
      var target = $(this).val();
      var data = {
        'target' : target,
        'action' : 'filter_blogposts'
      }

      $.ajax({
        url : '/wp-admin/admin-ajax.php',
        data : data,
        type : 'POST',
        beforeSend : function(){
          $('.ajax-container').html(' ');
          gsap.to(window, {duration: 1, scrollTo: 500});
        },
        success : function(response){
          $('.ajax-container').html(response);
        }
      });
    });
  },
  finalize(){}
}